import JigsawPuzzle from "./JigsawPuzzle";
import LibraObject from "./LibraObject";

export default class Libra extends JigsawPuzzle {
    constructor(config) {
        super(config);
        // Должны знать, какие объекты размещать, и подхватить их из инвентаря
        this.collectableType = config.collectableType;
        
        // Игровая область, куда можно ставить фигурки
        this.playableAreas = config.playableAreas || []
        
        // На какие позиции ставить объект, когда курсор в playableAreas
        // Распределять вдоль весов,
        // указана крайняя левая и крайняя правая точка для каждого из состояний весов
        this.objectPositions = config.objectPositions || []
        
        // В каком состоянии сейчас находятся весы
        this.mapState = config.mapState || 0;

        // Какой это соответствует картинке
        this.mapStatePictures = config.mapStatePictures || []

        // Корректные веса
        this.correctWeights = config.correctWeights || [16, 16]
        this.currentWeights = config.currentWeights || [0,0];
        this.libraObjects = config.libraObjects || [{}, {}];

        // Потенциально уже могут быть кусочки паззла для подсказки
        this.gameObjects = config.gameObjects || {};

        // Что происходит, когда паззл решен
        this.onSolveEvents = config.onSolveEvents || []

        this.draggingObject = null;
    }

    mountObjects() {

        Object.keys(this.gameObjectsSpawn).forEach(key => {
            let gameObject = this.gameObjectsSpawn[key];
            gameObject.id = key;
            gameObject.init({map: this,
                container:this.element});
        })

        // Функция достает объекты из инвентаря в область инициализации
        let inventoryCollections = this.quest.inventory.inventoryCollections;
        for (let i=0; i < inventoryCollections.length; i++) {
            if (inventoryCollections[i].collectableType === this.collectableType) {
                Object.keys(inventoryCollections[i].gameObjects).forEach(key => {
                    this.gameObjects[key] = inventoryCollections[i].gameObjects[key];
                });
            };
        };

        // Сортируем объекты для отображения в поле инициализации, сохраняем упорядоченный массив
        let gameObjectsOrdered = []
        for (var key in this.gameObjects) {
            gameObjectsOrdered.push([key, this.gameObjects[key]['order']]);
        }
        gameObjectsOrdered.sort(function(a, b) {
            return a[1] - b[1];
        });
        this.gameObjectsOrdered = gameObjectsOrdered;

        // console.log('GAMEOBJECTS', this.gameObjects)

        // Отображаем объекты в поле инициализации        
        for (let i=0; i < gameObjectsOrdered.length; i++) {
            let workingGameObject = this.gameObjects[gameObjectsOrdered[i][0]]
            // console.log(workingGameObject.name, workingGameObject.collectableType);

            // Как-то инициализируем объект
            let jigsawObject = 1;

            if (!this.customInitialization) {
                jigsawObject = new LibraObject({
                    name: workingGameObject.name,
                    weight: workingGameObject.weight,
                    collectableType: workingGameObject.collectableType,
                    x: this.xInitialization + this.initializationGapSize + 
                            (this.initializationGapSize + this.initializationPictureWidth) * i,
                    y: this.yInitialization + this.initializationGapSize,
                });
            } else {
                jigsawObject = new LibraObject({
                    name: workingGameObject.name,
                    weight: workingGameObject.weight,
                    collectableType: workingGameObject.collectableType,
                    x: this.customInitializationPositions[i][0],
                    y: this.customInitializationPositions[i][1],
                });
            }

            // Инициализируем объект паззла
            jigsawObject.init({
                map:this,
                container:this.element,
            });

            
            
            // Создаем отдельный массив с объектами паззла
            // console.log(jigsawObject);
            // this.jigsawObjects[jigsawObject.name] = jigsawObject;
        }

    }

}