export const utils = {

    wait(ms) {
        return new Promise(resolve => {
          setTimeout(() => {
            resolve();
          }, ms)
        })
    },

    getSize(quest) {
      let tf = 0
      if (quest === 'easyPandaQuest') {
        tf = 3840
      } else if (quest === 'pandaQuest') {
        tf = 3840
      } else if (quest === 'lakeMonsterQuest') {
        tf = 1920
      }

      return tf
    },

    getTransformFactor(quest) {

      let tf = 0

      if (quest === 'easyPandaQuest') {
        tf = 3840
      } else if (quest === 'pandaQuest') {
        tf = 3840
      } else if (quest === 'lakeMonsterQuest') {
        tf = 1920
      }
      // То как преобразовывать координаты. Если в фигме было 3840, то 3840
      let cw = document.querySelector(".game-container").clientWidth;
      let transformFactor = cw/tf;
      return transformFactor
    },

    getScaleFactor(quest) {

      let tf = 0

      if (quest === 'easyPandaQuest') {
        tf = 1920
      } else if (quest === 'pandaQuest') {
        tf = 1920
      } else if (quest === 'lakeMonsterQuest') {
        tf = 1920
      }
      // То как преобразовывать картинки. Если мы отскейлили 3840 на 50%, то 1920
      let cw = document.querySelector(".game-container").clientWidth;
      let transformFactor = cw/tf;
      return transformFactor
  },

  get_bif(key) {
    const bif = JSON.parse(process.env.REACT_APP_BIF);
    return bif[key]
  },

  get_gof(key) {
    const bif = JSON.parse(process.env.REACT_APP_GOF);
    return bif[key]
  }

}