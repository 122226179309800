import GameObject from "./GameObject";
import { utils } from "./utils";

export default class SoundMenu {
    constructor(config) {

        this.currentQuest = window.location.pathname.split('/')[2]
        let size = utils.getSize(this.currentQuest)

        this.map = config.map || null;
        this.src = config.src || `${process.env.REACT_APP_API_URL}${this.currentQuest}/images/backgrounds/soundMenu.${utils.get_bif(this.currentQuest)}`;
        
        this.x = config.x || 202 * (size/3840);
        this.y = config.y || 40 * (size/3840);

        this.down_src = config.down_src || `${process.env.REACT_APP_API_URL}${this.currentQuest}/images/navigation/sound-arrow-down.${utils.get_gof(this.currentQuest)}`;
        this.down_src_glow = config.down_src_glow || `${process.env.REACT_APP_API_URL}${this.currentQuest}/images/navigation/sound-arrow-down-glow.${utils.get_gof(this.currentQuest)}`;

        this.up_src = config.up_src || `${process.env.REACT_APP_API_URL}${this.currentQuest}/images/navigation/sound-arrow-up.${utils.get_gof(this.currentQuest)}`;
        this.up_src_glow = config.up_src_glow || `${process.env.REACT_APP_API_URL}${this.currentQuest}/images/navigation/sound-arrow-up-glow.${utils.get_gof(this.currentQuest)}`;

        this.x_up = config.x_up || 228 * (size/3840); // Смотрим относительно контейнера
        this.y_up = config.y_up || 82 * (size/3840);

        this.x_down = config.x_down || 228 * (size/3840);
        this.y_down = config.y_down || 123 * (size/3840);

        this.x_prcnt = config.x_prcnt || 301 * (size/3840);
        this.y_prcnt = config.y_prcnt || 88 * (size/3840);

    };

    createElement() {

        // Константы
        let transformFactor = utils.getTransformFactor(this.currentQuest);
        let scaleFactor = utils.getScaleFactor(this.currentQuest);
        let size = utils.getSize(this.map.currentQuest)

        // Создаем фон
        this.element = document.createElement("div");
        this.element.classList.add("SoundMenu");

        this.element.style.position = 'absolute'
        this.element.style['transform-origin'] = 'top left'
        this.element.style.left = `${this.x * transformFactor}px`;
        this.element.style.top = `${this.y * transformFactor}px`;
        this.element.style.transform = `scale(${scaleFactor})`

        // Загрузили фоновую картинку
        let backgroundImageElement = document.createElement("img");
        backgroundImageElement.setAttribute("src", this.src );
        backgroundImageElement.classList.add("SoundMenu");
        backgroundImageElement.style.position = 'absolute'
        backgroundImageElement.style['transform-origin'] = 'top left'

        this.element.appendChild(backgroundImageElement);

        // Добавляем % звука
        let soundVolume = document.createElement("div");
        soundVolume.classList.add("soundVolume");
        soundVolume.style.position = 'absolute'
        soundVolume.style['transform-origin'] = 'top left'
        soundVolume.style.top = `${this.y_prcnt * utils.getTransformFactor(this.currentQuest)}px`
        soundVolume.style.left = `${this.x_prcnt * utils.getTransformFactor(this.currentQuest)}px`
        soundVolume.style['z-index'] = 2 // Чтобы рисовалось поверх

        let width = 268 * utils.getTransformFactor(this.currentQuest) * (size/3840)
        let height = 52 * utils.getTransformFactor(this.currentQuest) * (size/3840)

        soundVolume.style.width = `${width}px`;
        soundVolume.style.height = `${height}px`;;
        

        soundVolume.innerHTML = (`
                            <svg x=0 y=0 width="${width}" height="${height}" xmlns="http://www.w3.org/2000/svg">
                                <rect class="fillContainerSound" x=0 y=0 height="100%" fill="#35180D" style="width: ${this.map.quest.soundVolume}%"/>
                            </svg>
                        `);
                        
                        
        soundVolume.querySelector("svg").style['vertical-align'] = 'top'
        document.querySelector(".QuestMap").appendChild(soundVolume)
        // this.element.appendChild(soundVolume)

        window.addEventListener('resize', () =>{
            let transformFactor = utils.getTransformFactor(this.currentQuest);
            let scaleFactor = utils.getScaleFactor(this.currentQuest);

            this.element.style.transform = `scale(${scaleFactor})`
            this.element.style.left = `${this.x * transformFactor}px`;
            this.element.style.top = `${this.y * transformFactor}px`;

            // document.querySelector(".soundVolume").style.transform = `scale(${scaleFactor})`
            document.querySelector(".soundVolume").style.left = `${3394 * transformFactor}px`;
            document.querySelector(".soundVolume").style.top = `${2040 * transformFactor}px`;

            let width = 268 * transformFactor
            let height = 52 * transformFactor

            document.querySelector(".soundVolume").style.width = `${width}px`;
            document.querySelector(".soundVolume").style.height = `${height}px`;;

            document.querySelector(".soundVolume").innerHTML = (`
                                <svg x=0 y=0 width="${width}" height="${height}" xmlns="http://www.w3.org/2000/svg">
                                    <rect class="fillContainerSound" x=0 y=0 height="100%" fill="#35180D" style="width: ${this.map.quest.soundVolume}%"/>
                                </svg>
                            `);
                            
            document.querySelector(".soundVolume").querySelector("svg").style['vertical-align'] = 'top'
                        
        });
  
        
    }
        
    addButtonElements() {
        
        let increaseSound = new GameObject({
            name: 'increaseSound',
            x: this.x_up,
            y: this.y_up,
            src: this.up_src,
            src_glow: this.up_src_glow,
            onClickEvents: [{type:'increaseSound'}],
            map: this.map,
        })

        increaseSound.init({map: this.map, container:document.querySelector('.QuestMap')})

        let decreaseSound = new GameObject({
            name: 'increaseSound',
            x: this.x_down,
            y: this.y_down,
            src: this.down_src,
            src_glow: this.down_src_glow,
            onClickEvents: [{type:'decreaseSound'}],
            map: this.map,
        })

        decreaseSound.init({map: this.map, container:document.querySelector('.QuestMap')})
        
    }

    init(container) {
        this.createElement();
        // console.log(this.element, container);
        // console.log('SOUND MENU', this)
        document.querySelector('.QuestMap').appendChild(this.element);
        this.addButtonElements();
    }

}