import JigsawObject from "./JigsawObject";
import { utils } from "./utils";

export default class LibraObject extends JigsawObject {
    constructor(config) {
        super(config);
    }

    returnToInitialization() {
        console.log('returnToInitialization, THE PLAYING AREA of this object is', this.playingArea);
        // Сменили картинку
        this.gameObjectElement.setAttribute("src", this.srcPlaySmall);

        // Изменили позицию на заданную
        this.x = this.initialX;
        this.y = this.initialY;

        // Расположили картинку на заданной позиции
        let transformFactor = utils.getTransformFactor(this.currentQuest);
        let scaleFactor = utils.getScaleFactor(this.currentQuest);

        this.gameObjectElement.style.transform = `scale(${scaleFactor})`
        this.gameObjectElement.style.left = `${this.x * transformFactor}px`;
        this.gameObjectElement.style.top = `${this.y * transformFactor}px`;

        // Меняем текущий мэтчинг
        // Условие на if нужно если мы из initialization сразу тыкнули мимо чаши
        if ((this.playingArea !== null) && this.playingArea >= 0) {
            console.log('LIBRA OBJECTS BEFORE REMOVAL', this.map.libraObjects);
            console.log('REMOVING', this.name, 'FROM PLAYING AREA', this.playingArea, 'IN', this.map.libraObjects)
            delete this.map.libraObjects[this.playingArea][this.name];
            this.map.currentWeights[this.playingArea] -= this.weight;
            console.log('LIBRA OBJECTS AFTER REMOVAL', this.map.libraObjects);
        }

        // Можно брать другой объект
        this.map.draggingObject = null;
        this.isDragging = false;

        this.isPlayModeImage = false;
        this.playingArea = null;
        console.log('returnToInitialization, NEW PLAYING AREA of this object is', this.playingArea);

        // Пересчет состояния карты
        if (this.map.currentWeights[0] === this.map.currentWeights[1]) {
            this.map.mapState = 0;
        } else if (this.map.currentWeights[0] > this.map.currentWeights[1]) {
            this.map.mapState = 2;
        } else if (this.map.currentWeights[0] < this.map.currentWeights[1]) {
            this.map.mapState = 1;
        }

        console.log('NEW STATE', this.map.mapState)

        this.map.quest.executeEvents([{type:'changeLevelPicture', map: "pandaTempleDay_Libra",
            newSrc: this.map.mapStatePictures[this.map.mapState]}]);

        let mapState = this.map.mapState;

        console.log('CURRENT WEIGHTS AND MAP STATE', this.map.currentWeights, this.map.mapState)

        // console.log(this.map.mapState, this.map.currentWeights);

        let libraObjects = this.map.libraObjects;
        let objectPositions = this.map.objectPositions;
        let playableAreas = this.map.playableAreas;

        // Сдвинули все объекты

        for (let i=0; i<playableAreas.length; i++) {
            let numObjects = Object.keys(libraObjects[i]).length;
            let x_length = objectPositions[mapState][i][1][0] - objectPositions[mapState][i][0][0];
            let y_length = objectPositions[mapState][i][1][1] - objectPositions[mapState][i][0][1];
            let x_step = x_length / numObjects;
            let y_step = y_length / numObjects;

            let counter = 0;
            
            Object.keys(this.map.libraObjects[i]).forEach(key => {
                let toX = objectPositions[mapState][i][0][0] + x_step * counter;
                let toY = objectPositions[mapState][i][0][1] + y_step * counter;
                this.map.libraObjects[i][key].moveObject(toX, toY)

                counter += 1
            })

            // Сдвинули все объекты на другой чаше
            let numObjects2 = Object.keys(libraObjects[1-i]).length;
            let x_length2 = objectPositions[mapState][1-i][1][0] - objectPositions[mapState][1-i][0][0];
            let y_length2 = objectPositions[mapState][1-i][1][1] - objectPositions[mapState][1-i][0][1];
            let x_step2 = x_length2 / numObjects2;
            let y_step2 = y_length2 / numObjects2;

            let counter2 = 0;
            
            Object.keys(this.map.libraObjects[1-i]).forEach(key => {
                let toX = objectPositions[mapState][1-i][0][0] + x_step2 * counter2;
                let toY = objectPositions[mapState][1-i][0][1] + y_step2 * counter2;
                this.map.libraObjects[1-i][key].moveObject(toX, toY)

                counter2 += 1
            })
        }

    }

    // Функция для сдвига объектов в рамках одной чаши весов
    moveObject(toX, toY) {

        // console.log(`I'm moving ${this.name} from ${this.x},${this.y} to ${toX}, ${toY}`)
        // Сменили картинку
        this.gameObjectElement.setAttribute("src", this.srcPlaySmall);

        // Изменили позицию на заданную
        this.x = toX;
        this.y = toY;

        // Расположили картинку на заданной позиции
        let transformFactor = utils.getTransformFactor(this.currentQuest);
        let scaleFactor = utils.getScaleFactor(this.currentQuest);

        this.gameObjectElement.style.transform = `scale(${scaleFactor})`
        this.gameObjectElement.style.left = `${this.x * transformFactor}px`;
        this.gameObjectElement.style.top = `${this.y * transformFactor}px`;

        // Можно брать другой объект
        this.map.draggingObject = null;
        this.isDragging = false;
        this.isPlayModeImage = false;
    }

    mountOnPosition() {
        console.log(this.map);

        this.isDragging = !this.isDragging;
        this.map.draggingObject = this.name;
        
        let playableAreas = this.map.playableAreas;
        let objectPositions = this.map.objectPositions;
        let libraObjects = this.map.libraObjects;

        let returnToInitialization = true;

        for (let i=0; i<playableAreas.length; i++) {
            if (this.x >= playableAreas[i][0][0] && this.x <= playableAreas[i][1][0] &&
                this.y >= playableAreas[i][0][1] && this.y <= playableAreas[i][1][1] &&
                !this.isDragging) {

                    console.log(`Got in mountOnPosition for stone ${this.name}`)

                    // Сменили картинку
                    this.gameObjectElement.setAttribute("src", this.srcPlay);
                    this.isPlayModeImage = true;

                    console.log(`You have the playing area ${this.playingArea}`)

                    // Если объект уже где-то стоял, чистим карту
                    if ((this.playingArea >= 0) && (this.playingArea !== null)) {
                        console.log('LIBRA OBJECTS BEFORE REMOVAL', this.map.libraObjects);
                        delete this.map.libraObjects[this.playingArea][this.name]
                        this.map.currentWeights[this.playingArea] -= this.weight;
                        console.log('LIBRA OBJECTS AFTER REMOVAL', this.map.libraObjects);
                    }

                    // Добавили наш объект в пул
                    this.playingArea = i;
                    this.map.libraObjects[i][this.name] = this;
                    this.map.currentWeights[i] += this.weight;

                    // Пересчет состояния карты
                    if (this.map.currentWeights[0] === this.map.currentWeights[1]) {
                        this.map.mapState = 0;
                    } else if (this.map.currentWeights[0] > this.map.currentWeights[1]) {
                        this.map.mapState = 2;
                    } else if (this.map.currentWeights[0] < this.map.currentWeights[1]) {
                        this.map.mapState = 1;
                    }

                    console.log('NEW STATE', this.map.mapState)

                    this.map.quest.executeEvents([{type:'changeLevelPicture', map: "pandaTempleDay_Libra", blocked: true,
                        newSrc: this.map.mapStatePictures[this.map.mapState]}]);

                    let mapState = this.map.mapState;

                    console.log('CURRENT WEIGHTS AND MAP STATE', this.map.currentWeights, this.map.mapState)

                    // console.log(this.map.mapState, this.map.currentWeights);

                    // Сдвинули все объекты
                    let numObjects = Object.keys(libraObjects[i]).length;
                    let x_length = objectPositions[mapState][i][1][0] - objectPositions[mapState][i][0][0];
                    let y_length = objectPositions[mapState][i][1][1] - objectPositions[mapState][i][0][1];
                    let x_step = x_length / numObjects;
                    let y_step = y_length / numObjects;

                    let counter = 0;
                    
                    Object.keys(this.map.libraObjects[i]).forEach(key => {
                        let toX = objectPositions[mapState][i][0][0] + x_step * counter;
                        let toY = objectPositions[mapState][i][0][1] + y_step * counter;
                        this.map.libraObjects[i][key].moveObject(toX, toY)

                        counter += 1
                    })

                    // Сдвинули все объекты на другой чаше
                    let numObjects2 = Object.keys(libraObjects[1-i]).length;
                    let x_length2 = objectPositions[mapState][1-i][1][0] - objectPositions[mapState][1-i][0][0];
                    let y_length2 = objectPositions[mapState][1-i][1][1] - objectPositions[mapState][1-i][0][1];
                    let x_step2 = x_length2 / numObjects2;
                    let y_step2 = y_length2 / numObjects2;

                    let counter2 = 0;
                    
                    Object.keys(this.map.libraObjects[1-i]).forEach(key => {
                        let toX = objectPositions[mapState][1-i][0][0] + x_step2 * counter2;
                        let toY = objectPositions[mapState][1-i][0][1] + y_step2 * counter2;
                        this.map.libraObjects[1-i][key].moveObject(toX, toY)

                        counter2 += 1
                    })


                    console.log('LIBRAOBJECTS', this.map.libraObjects)

                    // Не нужно возвращать на место инициализации
                    returnToInitialization = false;

                    // Можно брать другой объект
                    this.map.draggingObject = null;

                    // console.log(this.map.libraObjects)
            }
        }

        if (returnToInitialization && !this.isDragging) {
            this.returnToInitialization();
        }
    }

    init({map, container}) {
        this.createElement(map);
        this.isDragging = false;

        this.gameObjectElement.addEventListener('click', (e) => {

            // Мы реагируем на клик только если по карте сейчас пока ничего не перетаскивается
            if (!this.map.draggingObject || this.map.draggingObject === this.name) {
                this.mountOnPosition();
            } 
            
            // Проверка на то что паззл решен
            let puzzleSolved = true;

            for (let i=0; i<this.map.correctWeights.length; i++) {
                if (this.map.correctWeights[i] !== this.map.currentWeights[i]) {
                    puzzleSolved = false;
                    console.log(puzzleSolved);
                }
            }

            if (puzzleSolved) {
                console.log('HORRAY!');
                this.map.isSolved = true;
                console.log(this.map.quest, this.map.onSolveEvents);
                this.map.quest.executeEvents(this.map.onSolveEvents);
            }
        });
        
        this.movementHandler = e => {
            
            let rect = document.querySelector(".game-container").getBoundingClientRect();
            let gapBorderWidth = rect.left;
            let gapBorderHeight = rect.top;

            // console.log(e.clientX - gapBorderWidth, e.clientY - gapBorderHeight)

            if (!this.isDragging || this.map.isSolved) {
               return;
           }

            this.width = this.gameObjectElement.getBoundingClientRect().width;
            this.height = this.gameObjectElement.getBoundingClientRect().height;

            let tf = utils.getTransformFactor(this.currentQuest);
            let positionX = e.clientX - gapBorderWidth - this.width/2;
            let positionY = e.clientY - gapBorderHeight - this.height/2;

            this.x = (e.clientX - gapBorderWidth) / tf;
            this.y = (e.clientY - gapBorderHeight) / tf;

            // console.log(this.x, this.y)

            this.gameObjectElement.style.left = `${positionX}px`;
            this.gameObjectElement.style.top = `${positionY}px`;
       }

        window.addEventListener('mousemove', this.movementHandler);

        container.appendChild(this.gameObjectElement);
    }
}