import QuestMap from "./QuestMap";
import GameObject from "./GameObject";
import { utils } from "./utils";

export default class CodePuzzle extends QuestMap {
    constructor(config) {
        super(config);
        
        // Информация о том, решена ли загадка
        this.isSolved = false;

        this.onSolveEvents = config.onSolveEvents || []

        // Информация о коде
        this.correctCode = config.correctCode || '';
        this.currentCode = config.currentCode || '';
        this.possibleValues = config.possibleValues || '';

        // Кастомный формат текста
        this.backgroundColor = config.backgroundColor || '';
        this.fontSize = config.fontSize || 0;

        // Кастомный формат стрелок
        this.upArrowSrc = config.upArrowSrc || ``
        this.upArrowSrcGlow = config.upArrowSrcGlow || ``

        this.downArrowSrc = config.downArrowSrc || ``
        this.downArrowSrcGlow = config.downArrowSrcGlow || ``

        // Координаты
        this.x = config.x || 0; // Отступ слева
        this.xArrows = config.xArrows || 0; // Отступ слева для стрелок
        this.yArrowUp = config.yArrowUp || 0; // Отступ для верхних стрелок
        this.yBlock = config.yBlock || 0; // Отступ для кода
        this.yArrowDown = config.yArrowDown || 0; // Отступ для нижних стрелок

        // Размер контейнера для текста
        this.width = config.width || 0; // Ширина одной цифры
        this.height = config.height || 0; // Высота одной цифры
        this.space = config.space || 0; // Пробел между блоками кода

        this.gameObjects = config.gameObjects || {};
    }

    createElement() {
        this.element = document.createElement("div");
        this.element.classList.add("QuestMap");
        this.element.style.backgroundImage = `url('${this.src}')`;

        let transformFactor = utils.getTransformFactor(this.currentQuest);

        // Создаем отображение блоков c кодом
        for (let i=0; i<this.correctCode.length; i++) {
            let newTextElement = document.createElement("p");
            newTextElement.classList.add(`CodePuzzleText`);
            newTextElement.textContent = this.currentCode[i];
            newTextElement.style.position = `absolute`;
            newTextElement.style.left = `${this.x * transformFactor + 
                                                i * (this.width + this.space) * transformFactor}px`;
            newTextElement.style.top = `${this.yBlock * transformFactor}px`;
            newTextElement.style.height = `${this.height * transformFactor}px`;
            newTextElement.style.width = `${this.width * transformFactor}px`;

            newTextElement.style['background-color'] = this.backgroundColor;
            newTextElement.style['font-size'] = `${this.fontSize * transformFactor}px`;

            newTextElement.style['text-align'] = 'center';
            newTextElement.style['vertical-align'] = 'middle';
            newTextElement.style['line-height'] = `${this.height * transformFactor}px`;

            newTextElement.style['margin-top'] = `0px`;
            newTextElement.style['margin-bottom'] = `0px`;

            this.element.appendChild(newTextElement);
        };

    };


    addButtonElements() {
        for (let i=0; i<this.correctCode.length; i++) {
            let arrowUpButton = new GameObject({
                onClickEvents: [{type:'increaseCodeDigit', index: i, map: this}],
                map: this,
                src: this.upArrowSrc,
                src_glow: this.upArrowSrcGlow,
                x: this.xArrows + i * (this.width + this.space),
                y: this.yArrowUp,
            })
            arrowUpButton.init({map: this,
                container:this.element})

            let arrowDownButton = new GameObject({
                onClickEvents: [{type:'decreaseCodeDigit', index: i, map: this}],
                map: this,
                src: this.downArrowSrc,
                src_glow: this.downArrowSrcGlow,
                x: this.xArrows + i * (this.width + this.space),
                y: this.yArrowDown,
            })
            arrowDownButton.init({map: this,
                container:this.element})
        }
    };

    init(container) {
        this.createElement();
        container.insertBefore(this.element, container.firstChild);
        this.mountObjects();
        this.addNavigationElements();
        this.addButtonElements();
    };


        

}