import Inventory from "./Inventory";
import { utils } from "./utils";
import GameObject from "./GameObject";

export default class InventoryMenu extends Inventory {
    constructor(config) {
        super(config);

        this.currentQuest = window.location.pathname.split('/')[2]

        // Статичная картинка меню инвентаря, как он снизу там выглядит
        this.src = `${process.env.REACT_APP_API_URL}${this.currentQuest}/images/backgrounds/inventoryMenu-background.${utils.get_bif(this.currentQuest)}`;
        this.inventoryImage = new Image();
        this.inventoryImage.src = this.src;

        // Какую коллекцию открываем
        this.inventoryCollection = config.inventoryCollection || null;
        this.map = config.map; // Забираем карту, чтобы знать, куда возвращаться

        // На каком объекте находимся
        this.activeElement = 0;

        this.nameDecoded = {
            'dishes':'Тарелки с едой',
            'sculptures':'Скульптуры',
            'page':'Сказка про панду',
            'telescopePieces':'Осколки телескопа',
            'scroll':'Шифр',
            'pandaPictures':'Рисунки дедушки',
            'key':'Ключ',
            'stones':'Камни',
            'recorderCiphers':'Шифры для проигрывателя',
            'photos':'Фотографии',
            'letter':'Письмо дедушки',
        }
    }

    createElement() {

        let transformFactor = utils.getTransformFactor(this.currentQuest);
        let scaleFactor = utils.getScaleFactor(this.currentQuest);

        this.element = document.createElement("div");
        this.element.classList.add("InventoryMenu");

        //
        // Загрузили фоновую картинку
        let backgroundImageElement = document.createElement("img");
        backgroundImageElement.setAttribute("src", this.src );
        backgroundImageElement.style.transform = `scale(${scaleFactor})`;
        backgroundImageElement.classList.add("InventoryMenu");
        backgroundImageElement.style.left = `${324 * transformFactor}px`;
        backgroundImageElement.style.top = `${43 * transformFactor}px`;
        backgroundImageElement.style['z-index'] = 10

        // Для удобства сохраняем тип объекта
        let collectableType = this.inventoryCollection.collectableType;
        this.collectableType = collectableType;

        // Загружаем объекты и сортируем их по порядку
        let gameObjects = this.inventoryCollection.gameObjects;
        let gameObjectsOrdered = []

        for (var key in gameObjects) {
            gameObjectsOrdered.push([key, gameObjects[key]['order']]);
        }
        
        gameObjectsOrdered.sort(function(a, b) {
            return a[1] - b[1];
        });

        this.gameObjectsOrdered = gameObjectsOrdered;

        //
        // Создаем отображение текста
        let newTextElement = document.createElement("p");
        newTextElement.classList.add(`gameObjectName`);
        newTextElement.textContent = `${this.nameDecoded[collectableType]} ${this.activeElement + 1}/${gameObjectsOrdered.length}`;

        newTextElement.style.position = `absolute`;
        newTextElement.style.left = `${387*transformFactor}px`;
        newTextElement.style.top = `${87*transformFactor}px`;
        newTextElement.style.height = `${100*transformFactor}px`;
        newTextElement.style.width = `${1500*transformFactor}px`;
        newTextElement.style['font-size'] = `${100*transformFactor}px`;
        newTextElement.style['color'] = `#35180D`;
        newTextElement.style['background-color'] = `#89694D`;

        newTextElement.style['text-align'] = 'left';
        newTextElement.style['vertical-align'] = 'middle';
        newTextElement.style['line-height'] = `${100*transformFactor}px`;

        newTextElement.style['margin-top'] = `0px`;
        newTextElement.style['margin-bottom'] = `0px`;
        newTextElement.style['z-index'] = 11

        // Отображаем объект с текущим индексом
        let displayedGameObject = gameObjects[gameObjectsOrdered[this.activeElement][0]];
        let objectImageElement = document.createElement("img");
        objectImageElement.classList.add(`inventoryMenuImage`);

        objectImageElement.setAttribute("src", `${process.env.REACT_APP_API_URL}${this.currentQuest}/images/inventory/${displayedGameObject.name}-inventory-opened.${utils.get_gof(this.currentQuest)}`);
        objectImageElement.style.position = `absolute`;

        objectImageElement.style.left = `${602*transformFactor}px`;
        objectImageElement.style.top = `${263*transformFactor}px`;
        objectImageElement.style.transform = `scale(${scaleFactor})`;
        objectImageElement.style['z-index'] = 11

        this.element.appendChild(backgroundImageElement);
        this.element.appendChild(newTextElement);
        this.element.appendChild(objectImageElement);

        window.addEventListener('resize', () =>{
            let transformFactor = utils.getTransformFactor(this.currentQuest);
            let scaleFactor = utils.getScaleFactor(this.currentQuest);
            
            backgroundImageElement.style.transform = `scale(${scaleFactor})`
            backgroundImageElement.style.left = `${324 * transformFactor}px`;
            backgroundImageElement.style.top = `${43 * transformFactor}px`;
            backgroundImageElement.style['z-index'] = 10

            newTextElement.style.left = `${387*transformFactor}px`;
            newTextElement.style.top = `${87*transformFactor}px`;
            newTextElement.style.height = `${100*transformFactor}px`;
            newTextElement.style.width = `${1237*transformFactor}px`;
            newTextElement.style['font-size'] = `${100*transformFactor}px`;
            newTextElement.style['line-height'] = `${100*transformFactor}px`;

            newTextElement.style['margin-top'] = `0px`;
            newTextElement.style['margin-bottom'] = `0px`;
            newTextElement.style['z-index'] = 11

            objectImageElement.style.left = `${602*transformFactor}px`;
            objectImageElement.style.top = `${263*transformFactor}px`;
            objectImageElement.style.transform = `scale(${scaleFactor})`;
            objectImageElement.style['z-index'] = 11
        });

    };

    increaseImage() {
        if (this.activeElement + 1 < this.gameObjectsOrdered.length) {
            this.activeElement += 1;
        } else {
            this.activeElement = 0;
        }
        
        // Меняем текст
        let gameObjectName = this.element.querySelectorAll('.gameObjectName')[0];
        gameObjectName.textContent = `${this.nameDecoded[this.collectableType]} ${this.activeElement + 1}/${this.gameObjectsOrdered.length}`;

        // Меняем картинку
        let gameObjectImage = this.element.querySelectorAll('.inventoryMenuImage')[0];

        let gameObjects = this.inventoryCollection.gameObjects;
        let displayedGameObject = gameObjects[this.gameObjectsOrdered[this.activeElement][0]];

        gameObjectImage.setAttribute("src", `${process.env.REACT_APP_API_URL}${this.currentQuest}/images/inventory/${displayedGameObject.name}-inventory-opened.${utils.get_gof(this.currentQuest)}`);
    }

    decreaseImage() {
        if (this.activeElement === 0) {
            this.activeElement = this.gameObjectsOrdered.length - 1;
        } else {
            this.activeElement -= 1;
        }
        
        // Меняем текст
        let gameObjectName = this.element.querySelectorAll('.gameObjectName')[0];
        gameObjectName.textContent = `${this.nameDecoded[this.collectableType]} ${this.activeElement + 1}/${this.gameObjectsOrdered.length}`;

        // Меняем картинку
        let gameObjectImage = this.element.querySelectorAll('.inventoryMenuImage')[0];

        let gameObjects = this.inventoryCollection.gameObjects;
        let displayedGameObject = gameObjects[this.gameObjectsOrdered[this.activeElement][0]];
        
        gameObjectImage.setAttribute("src", `${process.env.REACT_APP_API_URL}${this.currentQuest}/images/inventory/${displayedGameObject.name}-inventory-opened.${utils.get_gof(this.currentQuest)}`);
    }

    addButtonElements() {

        let arrowLeftButton = new GameObject({
            name: "inventoryMenu-arrow-left",
            onClickEvents: [{type:'decreaseCodeImage', 
                            inventoryMenu: this}],
            map: this.map,
            src: `${process.env.REACT_APP_API_URL}${this.currentQuest}/images/navigation/inventoryMenu-arrow-left.${utils.get_gof(this.currentQuest)}`,
            src_glow: `${process.env.REACT_APP_API_URL}${this.currentQuest}/images/navigation/inventoryMenu-arrow-left-glow.${utils.get_gof(this.currentQuest)}`,
            x: 376,
            y: 1035,
            z: 11,
        })
        arrowLeftButton.init({map: this.map,
            container:this.element})

        let arrowRightButton = new GameObject({
            name: "inventoryMenu-arrow-right",
            onClickEvents: [{type:'increaseCodeImage', 
                            inventoryMenu: this}],
            map: this.map,
            src: `${process.env.REACT_APP_API_URL}${this.currentQuest}/images/navigation/inventoryMenu-arrow-right.${utils.get_gof(this.currentQuest)}`,
            src_glow: `${process.env.REACT_APP_API_URL}${this.currentQuest}/images/navigation/inventoryMenu-arrow-right-glow.${utils.get_gof(this.currentQuest)}`,
            x: 3239,
            y: 1035,
            z: 11,
        })
        arrowRightButton.init({map: this.map,
            container:this.element})

        let arrowBackButton = new GameObject({
            name: "inventoryMenu-arrow-back",
            onClickEvents: [{type:'changeMap', map: this.map.name}],
            map: this.map,
            src: `${process.env.REACT_APP_API_URL}${this.currentQuest}/images/navigation/inventoryMenu-arrow-back.${utils.get_gof(this.currentQuest)}`,
            src_glow: `${process.env.REACT_APP_API_URL}${this.currentQuest}/images/navigation/inventoryMenu-arrow-back-glow.${utils.get_gof(this.currentQuest)}`,
            isRemovable: false,
            x: 3365,
            y: 86,
            z: 11,
        })
        arrowBackButton.init({map: this.map,
            container:this.element})
            
    };

    init(container) {
        this.createElement();
        container.appendChild(this.element);
        this.addButtonElements();
    }
}