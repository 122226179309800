import InventoryObject from "./InventoryObject";
import { utils } from "./utils";

export default class InventoryCollection extends InventoryObject {
    constructor(config) {
        super(config);
        this.gameObjects = config.gameObjects || {};
        this.isClickable = true;
        this.onClickEvents = config.onClickEvents || 
                    [{type: "openInventoryMenu", inventoryCollection: this}];
    }

    addObject(gameObj) {
        this.gameObjects[gameObj.name] = gameObj;
    }

    init(container) {
        this.createElement();
        this.gameObjectElement.addEventListener('mouseover', () =>{
            if (this.isClickable) {
                this.gameObjectElement.setAttribute("src", this.src_glow);
            }
        });

        this.gameObjectElement.addEventListener('mouseout', () =>{
            if (this.isClickable) {
                this.gameObjectElement.setAttribute("src", this.src);
            }
        });

        this.gameObjectElement.addEventListener('click', () =>{
            if (this.isClickable) {

                let newList = [{type:'changeMap', map: this.inventory.quest.currentMap.name}]
                this.onClickEvents.forEach(e => {
                    newList.push(e)
                })
                
                this.inventory.quest.executeEvents(newList);

                // this.inventory.quest.executeEvents(this.onClickEvents);
            }
        });

        window.addEventListener('resize', () =>{
            console.log('resize!!!')
            let transformFactor = utils.getTransformFactor(this.currentQuest);
            let scaleFactor = utils.getScaleFactor(this.currentQuest);

            this.gameObjectElement.style.transform = `scale(${scaleFactor})`
            this.gameObjectElement.style.left = `${this.x * transformFactor}px`;
            this.gameObjectElement.style.top = `${this.y * transformFactor}px`;

            let previousTextElements = document.querySelectorAll(`p`);
            for (let i=0; i<previousTextElements.length; i++) {
                let element = previousTextElements[i];
                
                element.style.position = `absolute`;
                element.style.left = `${(126 + 320)*transformFactor + 454 * transformFactor * i}px`;
                element.style.top = `${45*transformFactor}px`;
                element.style.height = `${80*transformFactor}px`;
                element.style.width = `${1230*transformFactor}px`;
                element.style['font-size'] = `${80*transformFactor}px`;
                element.style['line-height'] = `${80*transformFactor}px`;
                }
            }
        );
        
        // console.log(container, this.gameObjectElement);
        container.appendChild(this.gameObjectElement);
    }
}