import GameObject from "./GameObject";
import { utils } from "./utils";
import KeyPressListener from "./KeyPressListener";

export default class NavigationButton extends GameObject {
    constructor(config) {

        super(config);

        this.map = {
            "ArrowLeft":"left",
            "KeyA": 'left',

            "ArrowRight":"right",
            "KeyD": 'right',
        }
        this.customClass = config.customClass;
        this.direction = config.direction;

        this.src = config.src || `${process.env.REACT_APP_API_URL}${this.currentQuest}/images/navigation/arrow-${this.direction}.${utils.get_gof(this.currentQuest)}`;
        this.src_glow = config.src_glow || `${process.env.REACT_APP_API_URL}${this.currentQuest}/images/navigation/arrow-${this.direction}-glowing.${utils.get_gof(this.currentQuest)}`;
        this.x = config.x;
        this.y = config.y;

        // Экономим время на постоянное задание координат, делаем их гибкими
        this.loadButtonParameters();
    };
    
    loadButtonParameters() {

        let size = utils.getSize(this.currentQuest)

        // Дефолтное местонахождение кнопок в размерности (3840, 2160)
        // Чтобы перейти к другой размерности, скейлим на size
        if (this.x) {

        } else {
            switch(this.direction) {
                case 'left':
                    this.x = 29 / 3840 * size;
                    this.y = 1067 / 3840 * size;
                    break;
                case 'right':
                    this.x = 3691 / 3840 * size;
                    this.y = 1067 / 3840 * size;
                    break;
                case 'back':
                    this.x = 10 / 3840 * size;
                    this.y = 1900 / 3840 * size;
                    break;
                case 'default':
                    this.x = 153 / 3840 * size;
                    this.y = 162 / 3840 * size;
            }
        }
    }

    createElement(map) {
        this.gameObjectElement = document.createElement("img");
        this.gameObjectElement.classList.add("GameObject");
        this.gameObjectElement.classList.add("NavigationButton");

        if (this.customClass) {
            this.gameObjectElement.classList.add(this.customClass);
        }

        this.gameObjectElement.setAttribute("src", this.src );
        
        let transformFactor = utils.getTransformFactor(this.currentQuest);
        let scaleFactor = utils.getScaleFactor(this.currentQuest);

        this.gameObjectElement.style.left = `${this.x*transformFactor}px`;
        this.gameObjectElement.style.top = `${this.y*transformFactor}px`; 
        this.gameObjectElement.style.transform = `scale(${scaleFactor})`

        this.map = map;

        // switch(this.direction) {
        //     case 'left':
        //         this.actionListener = new KeyPressListener("ArrowLeft", () => {
        //             this.actionListener.unbind();
        //             this.map.quest.executeEvents(this.onClickEvents);
        //             console.log('ArrowLeft', this.onClickEvents)
        //             })

        //         // this.actionListener = new KeyPressListener("KeyA", () => {
        //         //     this.actionListener.unbind();
        //         //     this.map.quest.executeEvents(this.onClickEvents);
        //         //     })
        //         break;
        //     case 'right':
        //         this.actionListener = new KeyPressListener("ArrowRight", () => {
        //             this.actionListener.unbind();
        //             console.log('ArrowRight', this.onClickEvents)
        //             this.map.quest.executeEvents(this.onClickEvents);
        //             })

        //         // this.actionListener = new KeyPressListener("KeyD", () => {
        //         //     this.actionListener.unbind();
        //         //     this.map.quest.executeEvents(this.onClickEvents);
        //         //     })
        //         break;
        //     case 'back':
        //         this.actionListener = new KeyPressListener("Escape", () => {
        //             this.actionListener.unbind();
        //             console.log('Escape', this.onClickEvents)
        //             this.map.quest.executeEvents(this.onClickEvents);
        //             })
        //         break;
        //     case 'default':
        // }
        
    }

}