import GameObject from "./GameObject";
import NavigationButton from "./NavigationButton";
import SoundMenu from "./SoundMenu";
import { utils } from "./utils";
// import QuestEvent from "./QuestEvent";

export default class QuestMap {
    constructor(config) {
        // ID уровня
        this.name = config.name;
        this.class = config.class;

        // Объекты на карте
        this.gameObjects = config.gameObjects || {};

        // Ссылка на квест
        this.quest = config.quest || null;
        this.currentQuest = window.location.pathname.split('/')[2]

        // Изображение уровня
        this.levelImage = new Image();
        this.levelImage.src = config.src || `${process.env.REACT_APP_API_URL}${this.currentQuest}/images/backgrounds/${this.name}.${utils.get_bif(this.currentQuest)}`;
        this.src = config.src || `${process.env.REACT_APP_API_URL}${this.currentQuest}/images/backgrounds/${this.name}.${utils.get_bif(this.currentQuest)}`;

        // this.customArrows = config.customArrows; // true/false
        this.customArrowsSrc = config.customArrowsSrc || {};
        
    };

    createElement() {
        this.element = document.createElement("div");
        this.element.classList.add("QuestMap");
        this.element.style.backgroundImage = `url('${this.src}')`;
    };

    mountObjects() {
        // console.log('MAP GAMEOBJECTS', this.gameObjects)
        Object.keys(this.gameObjects).forEach(key => {
            let gameObject = this.gameObjects[key];
            // this.gameObjects[key] = gameObject;

            gameObject.id = key;
            gameObject.init({map: this,
                container:this.element});
        })
    };

    removeObject(gameObjectName) {

        if (document.getElementsByClassName(`GameObject ${gameObjectName}`)[0]) {
            document.getElementsByClassName(`GameObject ${gameObjectName}`)[0].remove();
        }
        delete this.gameObjects[gameObjectName];
       
    }

    addObject(gameObj) {
        let addContainer = document.querySelectorAll(`.QuestMap`)[0];
        if (!(gameObj.name in this.gameObjects)) {
            this.gameObjects[gameObj.name] = new GameObject(gameObj);
            this.gameObjects[gameObj.name].init({map: this,
                container:addContainer});
        }
    }

    addNavigationElements() {
        Object.keys(this.quest.navigationStructure[this.name]).forEach(key => {

            if (Object.keys(this.customArrowsSrc).includes(key)) {
                let navigationButton = new NavigationButton({
                    direction: key,
                    src: this.customArrowsSrc[key][0],
                    src_glow: this.customArrowsSrc[key][1],
                    x: this.customArrowsSrc[key][2],
                    y: this.customArrowsSrc[key][3],
                    onClickEvents: [{type:'changeMap', map: this.quest.navigationStructure[this.name][key]}],
                    map: this.name,
                })
                navigationButton.init({map: this,
                    container:this.element})
            } else {
                let navigationButton = new NavigationButton({
                    direction: key,
                    onClickEvents: [{type:'changeMap', map: this.quest.navigationStructure[this.name][key]}],
                    map: this.name,
                })
                navigationButton.init({map: this,
                    container:this.element})
            }
        })

        let navigationButton = new GameObject({
            name: 'hintsMenuButton',
            x: 30,
            y: 30,
            src: `${process.env.REACT_APP_API_URL}${this.currentQuest}/images/backgrounds/hintsMenuButton.${utils.get_gof(this.currentQuest)}`,
            src_glow: `${process.env.REACT_APP_API_URL}${this.currentQuest}/images/backgrounds/hintsMenuButton-glow.${utils.get_gof(this.currentQuest)}`,
            onClickEvents: [{type:'openHintsMenu'}],
            map: this.name,
        })

        navigationButton.init({map: this,
            container:this.element})

        if (this.quest.mapEnabled) {
            let mapMenuButton = new GameObject({
                name: 'mapMenuButton',
                x: 3494,
                y: 0,
                src: `${process.env.REACT_APP_API_URL}${this.currentQuest}/images/backgrounds/mapMenuButton.${utils.get_gof(this.currentQuest)}`,
                src_glow: `${process.env.REACT_APP_API_URL}${this.currentQuest}/images/backgrounds/mapMenuButton-glow.${utils.get_gof(this.currentQuest)}`,
                onClickEvents: [{type:'openMapMenu'}],
                map: this.name,
            })
    
            mapMenuButton.init({map: this,
                container:this.element})
        }

        let soundMenu = new SoundMenu({
            map: this,
        })

        soundMenu.init({map: this,
            container:this.element})
    };

    init(container) {
        this.createElement();

        container.insertBefore(this.element, container.firstChild);

        this.mountObjects();
        this.addNavigationElements();
    };

}

