import QuestMap from "./QuestMap";
import NavigationButton from "./NavigationButton";
import GameObject from "./GameObject";
import { utils } from "./utils";

export default class MapMenu extends QuestMap {
    constructor(config) {
        super(config);

        this.backButtonRedirect = config.backButtonRedirect;
    }

    addNavigationElements() {
        let navigationButton = new NavigationButton({
            direction: 'back',
            onClickEvents: [{type:'changeMap', map: this.backButtonRedirect}],
            map: this.name,
        })

        navigationButton.init({map: this,
            container:this.element})

        let hintsButton = new GameObject({
            name: 'hintsMenuButton',
            x: 30,
            y: 30,
            src: `${process.env.REACT_APP_API_URL}${this.currentQuest}/images/backgrounds/hintsMenuButton.${utils.get_gof(this.currentQuest)}`,
            src_glow: `${process.env.REACT_APP_API_URL}${this.currentQuest}/images/backgrounds/hintsMenuButton-glow.${utils.get_gof(this.currentQuest)}`,
            onClickEvents: [{type:'openHintsMenu'}],
            map: this.name,
        })

        hintsButton.init({map: this,
            container:this.element})

    };
}