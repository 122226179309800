import QuestMap from "./QuestMap";
import GameObject from "./GameObject";
import JigsawObject from "./JigsawObject";

export default class JigsawPuzzle extends QuestMap {
    constructor(config) {
        super(config);
        // Должны знать, какие объекты размещать, и подхватить их из инвентаря
        this.collectableType = config.collectableType;

        // Должны знать, где область инициализации объектов
        // Если кастомная инициализация, даются (x,y) по типу objectPositions
        this.customInitialization = config.customInitialization || false;
        this.customInitializationPositions = config.customInitializationPositions || [];

        // В противном случае все в рядочек фигачим
        this.xInitialization = config.xInitialization || 0;
        this.yInitialization = config.yInitialization || 0;
        this.initializationGapSize = config.initializationGapSize || 0;
        this.initializationPictureWidth = config.initializationPictureWidth || 0;

        // Игровая область, куда можно ставить фигурки
        // [[[x11,y11], [x12,y12]], [[x21,y21], [x22,y22]]] и тп
        this.playableAreas = config.playableAreas || []
        
        // На какие позиции ставить объект, когда курсор в playableAreas
        // Не должны пересекаться
        this.objectPositions = config.objectPositions || []
        
        // Корректный мэтчинг: индекс - имя объекта
        this.correctMatching = config.correctMatching || {}

        this.currentMatching = {};
        this.jigsawObjects = {}

        // Потенциально уже могут быть кусочки паззла для подсказки
        this.gameObjects = config.gameObjects || {};

        // Что происходит, когда паззл решен
        this.onSolveEvents = config.onSolveEvents || []

        this.draggingObject = null;
        this.gameObjectsSpawn = config.gameObjectsSpawn || {};
    }

    mountObjects() {
        Object.keys(this.gameObjectsSpawn).forEach(key => {
            let gameObject = this.gameObjectsSpawn[key];
            gameObject.id = key;
            gameObject.init({map: this,
                container:this.element});
        })

        // Функция достает объекты из инвентаря в область инициализации
        let inventoryCollections = this.quest.inventory.inventoryCollections;
        for (let i=0; i < inventoryCollections.length; i++) {
            if (inventoryCollections[i].collectableType === this.collectableType) {
                Object.keys(inventoryCollections[i].gameObjects).forEach(key => {
                    this.gameObjects[key] = inventoryCollections[i].gameObjects[key];
                });
            };
        };

        // console.log(this.gameObjects);

        // Сортируем объекты для отображения в поле инициализации, сохраняем упорядоченный массив
        let gameObjectsOrdered = []
        for (var key in this.gameObjects) {
            gameObjectsOrdered.push([key, this.gameObjects[key]['order']]);
        }
        gameObjectsOrdered.sort(function(a, b) {
            return a[1] - b[1];
        });
        this.gameObjectsOrdered = gameObjectsOrdered;

        // Отображаем объекты в поле инициализации        
        for (let i=0; i < gameObjectsOrdered.length; i++) {
            let workingGameObject = this.gameObjects[gameObjectsOrdered[i][0]]
            // console.log(workingGameObject.name, workingGameObject.collectableType);

            // Как-то инициализируем объект
            let jigsawObject = 1;

            if (!this.customInitialization) {
                console.log('GAPS',this.initializationGapSize , this.initializationPictureWidth, i)
                jigsawObject = new JigsawObject({
                    name: workingGameObject.name,
                    collectableType: workingGameObject.collectableType,
                    x: this.xInitialization + (this.initializationGapSize + this.initializationPictureWidth) * i,
                    y: this.yInitialization,
                });
            } else {
                jigsawObject = new JigsawObject({
                    name: workingGameObject.name,
                    collectableType: workingGameObject.collectableType,
                    x: this.customInitializationPositions[i][0],
                    y: this.customInitializationPositions[i][1],
                });
            }

            // Инициализируем объект паззла
            jigsawObject.init({
                map:this,
                container:this.element,
            });
            
            // Создаем отдельный массив с объектами паззла
            // console.log(jigsawObject);
            this.jigsawObjects[jigsawObject.name] = jigsawObject;
        }

    }

    // init() и createElement() не меняются, только тип gameObject'а
}