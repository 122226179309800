import GameObject from "./GameObject";
import { utils } from "./utils";

export default class JigsawObject extends GameObject {
    constructor(config) {
        super(config);
        this.initialX = config.x;
        this.initialY = config.y;
        this.isPlayModeImage = false;
    };

    createElement(map) {
        
        this.map = map;
        
        // console.log(this.name);
        // console.log(this.map);
        
        this.srcPlay = `${process.env.REACT_APP_API_URL}${this.currentQuest}/images/puzzles/${this.map.name}-${this.name}-play.${utils.get_gof(this.currentQuest)}`
        this.srcPlaySmall = `${process.env.REACT_APP_API_URL}${this.currentQuest}/images/puzzles/${this.map.name}-${this.name}-play-small.${utils.get_gof(this.currentQuest)}`

        this.gameObjectElement = document.createElement("img");
        this.gameObjectElement.classList.add("GameObject");
        this.gameObjectElement.setAttribute("src", this.srcPlaySmall);
        this.gameObjectElement.setAttribute("id", this.name);

        let transformFactor = utils.getTransformFactor(this.currentQuest);
        let scaleFactor = utils.getScaleFactor(this.currentQuest);

        this.gameObjectElement.style.transform = `scale(${scaleFactor})`
        this.gameObjectElement.style.left = `${this.x * transformFactor}px`;
        this.gameObjectElement.style.top = `${this.y * transformFactor}px`;

    }

    returnToInitialization() {
        // Сменили картинку
        this.gameObjectElement.setAttribute("src", this.srcPlaySmall);

        // Изменили позицию на заданную
        this.x = this.initialX;
        this.y = this.initialY;

        // Расположили картинку на заданной позиции
        let transformFactor = utils.getTransformFactor(this.currentQuest);
        let scaleFactor = utils.getScaleFactor(this.currentQuest);

        this.gameObjectElement.style.transform = `scale(${scaleFactor})`
        this.gameObjectElement.style.left = `${this.x * transformFactor}px`;
        this.gameObjectElement.style.top = `${this.y * transformFactor}px`;

        // Меняем текущий мэтчинг
        Object.keys(this.map.currentMatching).forEach(key => {
            if (this.map.currentMatching[key] === this.name) {
                // Для паззла
                delete this.map.currentMatching[key];
            }
        })

        // Можно брать другой объект
        this.map.draggingObject = null;
        this.isDragging = false;

        this.isPlayModeImage = false;
    }

    mountOnPosition() {
        this.isDragging = !this.isDragging;
        this.map.draggingObject = this.name;

        let playableAreas = this.map.playableAreas;
        let objectPositions = this.map.objectPositions;

        let returnToInitialization = true;

        let tf = utils.getTransformFactor(this.currentQuest);

        for (let i=0; i<playableAreas.length; i++) {
            if (this.x >= playableAreas[i][0][0] && this.x <= playableAreas[i][1][0] &&
                this.y >= playableAreas[i][0][1] && this.y <= playableAreas[i][1][1] &&
                !this.isDragging) {

                    // Сменили картинку
                    this.gameObjectElement.setAttribute("src", this.srcPlay);
                    this.isPlayModeImage = true;

                    // Изменили позицию на заданную
                    this.x = objectPositions[i][0];
                    this.y = objectPositions[i][1];

                    // Расположили картинку на заданной позиции
                    this.gameObjectElement.style.left = `${this.x * tf}px`;
                    this.gameObjectElement.style.top = `${this.y * tf}px`;
                    
                    // Меняем текущий мэтчинг
                    Object.keys(this.map.currentMatching).forEach(key => {
                        if (this.map.currentMatching[key] === this.name) {
                            delete this.map.currentMatching[key];
                        }
                    })

                    // Убираем другой предмет, если он там стоял
                    if (i in this.map.currentMatching && this.map.currentMatching[i] !== this.name) {
                        this.map.jigsawObjects[this.map.currentMatching[i]].returnToInitialization();
                    }
                    
                    this.map.currentMatching[i] = this.name;
                    
                    // Не нужно возвращать на место инициализации
                    returnToInitialization = false;

                    // Можно брать другой объект
                    this.map.draggingObject = null;
            }
        }
    

        if (returnToInitialization && !this.isDragging) {
            this.returnToInitialization();
        }
    }

    init({map, container}) {
        this.createElement(map);
        this.isDragging = false;

        this.gameObjectElement.addEventListener('click', (e) => {

            // Мы реагируем на клик только если по карте сейчас пока ничего не перетаскивается
            if (!this.map.draggingObject || this.map.draggingObject === this.name) {
                this.mountOnPosition();
            } else if (this.map.draggingObject && this.map.draggingObject !== this.name) {
                this.map.jigsawObjects[this.map.draggingObject].mountOnPosition();
                this.returnToInitialization(); 
            }

            // console.log(this.map.correctMatching, this.map.currentMatching);

            // Проверка на то что паззл решен
            let puzzleSolved = true;

            // Проверяем сначала, что все фигурки расставлены
            if (Object.keys(this.map.correctMatching).length === Object.keys(this.map.currentMatching).length) {
                // А потом что совпадают значения ключей
                Object.keys(this.map.correctMatching).forEach(key => {
                    if (this.map.correctMatching[key] !== this.map.currentMatching[key]) {
                        puzzleSolved = false;
                    }
                })
            } else {
                puzzleSolved = false;
            }

            if (puzzleSolved) {
                console.log('HORRAY!');
                this.map.isSolved = true;
                this.map.quest.executeEvents(this.map.onSolveEvents);
            }

        });
        
        this.movementHandler = e => {
            
            let rect = document.querySelector(".game-container").getBoundingClientRect();
            let gapBorderWidth = rect.left;
            let gapBorderHeight = rect.top;

            // console.log(e.clientX - gapBorderWidth, e.clientY - gapBorderHeight)

            if (!this.isDragging || this.map.isSolved) {
               return;
           }

            this.width = this.gameObjectElement.getBoundingClientRect().width;
            this.height = this.gameObjectElement.getBoundingClientRect().height;

            let tf = utils.getTransformFactor(this.currentQuest);
            let positionX = e.clientX - gapBorderWidth - this.width/2;
            let positionY = e.clientY - gapBorderHeight - this.height/2;

            this.x = (e.clientX - gapBorderWidth) / tf;
            this.y = (e.clientY - gapBorderHeight) / tf;

            // console.log(this.x, this.y)

            this.gameObjectElement.style.left = `${positionX}px`;
            this.gameObjectElement.style.top = `${positionY}px`;
       }

        window.addEventListener('mousemove', this.movementHandler);

        window.addEventListener('resize', () =>{
            console.log('resize!!!')
            let transformFactor = utils.getTransformFactor(this.currentQuest);
            let scaleFactor = utils.getScaleFactor(this.currentQuest);

            this.gameObjectElement.style.transform = `scale(${scaleFactor})`
            this.gameObjectElement.style.left = `${this.x * transformFactor}px`;
            this.gameObjectElement.style.top = `${this.y * transformFactor}px`;
        });
        

        container.appendChild(this.gameObjectElement);
    }


}