import QuestMap from "./QuestMap";
import GameObject from "./GameObject";
import { utils } from "./utils";

export default class MatchingPuzzle extends QuestMap {
    constructor(config) {
        super(config);
        
        // Игровые области для соединения
        this.playableAreasLeft = config.playableAreasLeft || [
                                [[1553,  244], [1553+293,  244+275]],
                                [[1553, 559],[1553+293, 559+275]],
                                [[1553, 874],[1553+293, 874+275]],    
                                [[1553, 1191],[1553+293, 1191+275]],
                                [[1553, 1506],[1553+293, 1506+275]],
                                [[1553, 1821],[1553+293, 1821+275]],
                                ]

        this.playableAreasRight = config.playableAreasRight || [
            [[1993,  244], [1993+293,  244+275]],
            [[1993, 559],[1993+293, 559+275]],
            [[1993, 874],[1993+293, 874+275]],    
            [[1993, 1191],[1993+293, 1191+275]],
            [[1993, 1506],[1993+293, 1506+275]],
            [[1993, 1821],[1993+293, 1821+275]],
            ]
        
        // Координаты центров кружков
        this.leftLineCoordinates = [[1645, 382],
                                    [1645, 697],
                                    [1645, 1010],
                                    [1645, 1331],
                                    [1645, 1646],
                                    [1645, 1959]]
        this.rightLineCoordinates = [[2195, 382],
                                    [2195, 697],
                                    [2195, 1010],
                                    [2195, 1331],
                                    [2195, 1646],
                                    [2195, 1959]]
        
        // Координаты кружков, которые должны быть соединены
        this.leftObjectPositions = config.leftObjectPositions || [[1605,342], 
                                                                    [1605,657], 
                                                                    [1605,972], 
                                                                    [1605, 1291],
                                                                    [1605,1606], 
                                                                    [1605, 1921]]

        this.rightObjectPositions = config.rightObjectPositions || [[2155,342], 
                                                                    [2155,657], 
                                                                    [2155,972], 
                                                                    [2155, 1291],
                                                                    [2155,1606], 
                                                                    [2155, 1921]]
        
        // Корректный мэтчинг: индекс - имя объекта
        this.correctMatching = config.correctMatching || {
            0: 2,
            1: 4,
            2: 1,
            3: 5,
            4: 0,
            5: 3,
        }

        this.currentMatching = {};

        this.nodeImage = `${process.env.REACT_APP_API_URL}pandaQuest/images/puzzles/${this.name}-nodeImage.${utils.get_gof(this.currentQuest)}`
        this.nodeImageGlow = `${process.env.REACT_APP_API_URL}pandaQuest/images/puzzles/${this.name}-nodeImage-glow.${utils.get_gof(this.currentQuest)}`

        // Потенциально уже могут быть кусочки паззла для подсказки
        this.gameObjectsLeft = {};
        this.gameObjectsRight = {};

        this.isDraggingLeft = -1;
        this.isDraggingRight = -1;

        this.lineObjectsInfo = {};

        // Что происходит, когда паззл решен
        this.onSolveEvents = config.onSolveEvents || [
                {type:'increaseHintsCounter', stage:19, throwOut:true},
                {type:'changeMap', map:'final'},
                {type:'addRedirect', 
                    mapFrom: 'endingMatching', 
                    mapTo:'final'},
            ]

    }

    mountObjects() {
        // console.log('MAP GAMEOBJECTS', this.gameObjects)

        for (let i=0; i<this.leftObjectPositions.length; i++) {
            let gameObjectName = `leftObject${i}`
            let gameObject = new GameObject({
                name: gameObjectName,
                // onClickEvents:[{type:'increaseHintsCounter', stage:0}],
                src: this.nodeImage,
                src_glow: this.nodeImageGlow,
                x: this.leftObjectPositions[i][0],
                y: this.leftObjectPositions[i][1],
            })

            gameObject.init({map: this,
            container:this.element});

            this.gameObjectsLeft[gameObjectName] = gameObject;
        }

        for (let i=0; i<this.rightObjectPositions.length; i++) {
            let gameObjectName = `rightObject${i}`
            let gameObject = new GameObject({
                name: gameObjectName,
                // onClickEvents:[{type:'increaseHintsCounter', stage:0}],
                src: this.nodeImage,
                src_glow: this.nodeImageGlow,
                x: this.rightObjectPositions[i][0],
                y: this.rightObjectPositions[i][1],
            })

            gameObject.init({map: this,
            container:this.element});

            this.gameObjectsRight[gameObjectName] = gameObject;
        }

    };


    init(container) {
        this.createElement();
        container.insertBefore(this.element, container.firstChild);

        this.mountObjects();
        this.addNavigationElements();


        this.clickHandler = e => {
                
            let rect = document.querySelector(".game-container").getBoundingClientRect();
            let gapBorderWidth = rect.left;
            let gapBorderHeight = rect.top;

            // let positionX = e.clientX - gapBorderWidth;
            // let positionY = e.clientY - gapBorderHeight;

            let tf = utils.getTransformFactor(this.currentQuest);

            let positionX = (e.clientX - gapBorderWidth) / tf;
            let positionY = (e.clientY - gapBorderHeight) / tf;

            console.log(positionX, positionY)


            for (let i=0; i<this.playableAreasLeft.length; i++) {
                if (positionX >= this.playableAreasLeft[i][0][0] && positionX <= this.playableAreasLeft[i][1][0] &&
                    positionY >= this.playableAreasLeft[i][0][1] && positionY <= this.playableAreasLeft[i][1][1]) {

                    console.log(`I'm in the playable area left`, i, this.isDraggingLeft)

                    if (this.isDraggingRight >= 0) {
                        // Соединяем
                        console.log('scenario this.isDraggingRight')
                        this.gameObjectsLeft[`leftObject${i}`].changeObjectPicture(this.nodeImageGlow, this.nodeImageGlow, this.quest, this.name)
                        this.isDraggingLeft = i

                        // Соединяем
                        let qmap = document.querySelector(".QuestMap").getBoundingClientRect();
                        console.log(qmap)
                        
                        let transformFactor = utils.getTransformFactor(this.currentQuest);

                        let newLineObject = document.createElement("div");

                        let x1 = 0
                        let y1 = 0                        
                        let x2 = (this.rightLineCoordinates[this.isDraggingRight][0] - this.leftLineCoordinates[i][0]) * transformFactor
                        let y2 = (this.rightLineCoordinates[this.isDraggingRight][1] - this.leftLineCoordinates[i][1]) * transformFactor

                        let height = 0;
                        let width = 0;

                        let left = 0;
                        let top = 0;

                        if (y2 >= 0) {
                            newLineObject.style.left = `${this.leftLineCoordinates[i][0] * transformFactor}px`;
                            newLineObject.style.top = `${this.leftLineCoordinates[i][1] * transformFactor}px`;

                            left = this.leftLineCoordinates[i][0] * transformFactor;
                            top = this.leftLineCoordinates[i][1] * transformFactor;
                        

                            height = y2-y1+100;
                            width = x2-x1;
                        } else if (y2 < 0) {
                            newLineObject.style.left = `${this.leftLineCoordinates[i][0] * transformFactor}px`;
                            newLineObject.style.top = `${this.rightLineCoordinates[this.isDraggingRight][1] * transformFactor}px`;

                            left = this.leftLineCoordinates[i][0] * transformFactor;
                            top = this.rightLineCoordinates[this.isDraggingRight][1] * transformFactor;


                            height = -y2
                            width = x2-x1

                            let y1_ = -y2
                            let y2_ = 0

                            y1 = y1_
                            y2 = y2_
                        }

                        let stroke = 5;

                        if (((y1 - y2 < 1) && (y1 >= y2)) || ((y2 - y1 < 1) && (y1 <= y2))) {
                            console.log(y1, y2)
                            stroke = 10;
                        }

                        
                        newLineObject.innerHTML = (`
                            <svg height="${height}" width="${width}" xmlns="http://www.w3.org/2000/svg">
                                <line x1="${x1}" y1="${y1}" x2="${x2}" y2="${y2}" style="stroke:black;stroke-width:${stroke}" />
                            </svg>
                        `);
                        newLineObject.style['position'] = 'absolute'
                        newLineObject.style['transform-origin'] = 'top left'
                        newLineObject.classList.add(`line-${this.isDraggingLeft}`);

                        this.lineObjectsInfo[`line-${this.isDraggingLeft}`] = [height, width, x1, y1, x2, y2, left, top, transformFactor]
                        
                        console.log('NEW LINE OBJECT', newLineObject)
                        
                        console.log('Dragging objects', this.isDraggingLeft, this.isDraggingRight)

                        // Удаляем все мэтчи, которые связывали this.isDraggingLeft или this.isDraggingRight
                        Object.keys(this.currentMatching).forEach(key => {
                             if ((Number(key) === this.isDraggingLeft) ) {
                                // Меняем картинку тому ноду, который был соединен с левым
                                console.log('Мы должны поменять картинку правому ноду')

                                if (this.currentMatching[key] !== this.isDraggingRight) {
                                    this.gameObjectsRight[`rightObject${this.currentMatching[key]}`].changeObjectPicture(this.nodeImage, this.nodeImageGlow, this.quest, this.name)
                                }
                                document.querySelector(`.line-${key}`).remove()
                                // console.log(document.querySelectorAll(`.line-${key}`), `line-${key}` )
                                delete this.currentMatching[key]
                            }

                            // if ((this.currentMatching[key] === this.isDraggingRight) && (Number(key) !== this.isDraggingLeft)) {
                            if ((this.currentMatching[key] === this.isDraggingRight)) {
                                console.log('Мы должны поменять картинку левому ноду')
                                
                                if (Number(key) !== this.isDraggingLeft) {
                                    this.gameObjectsLeft[`leftObject${key}`].changeObjectPicture(this.nodeImage, this.nodeImageGlow, this.quest, this.name)
                                }
                                document.querySelector(`.line-${key}`).remove()
                                // console.log(document.querySelectorAll(`.line-${key}`), `line-${key}` )
                                delete this.currentMatching[key]
                            }
                        })

                        container = document.querySelector(".QuestMap")
                        container.insertBefore(newLineObject, container.firstChild);

                        // Создаем новый матч
                        this.currentMatching[this.isDraggingLeft] = this.isDraggingRight

                        console.log(this.currentMatching)

                        this.isDraggingLeft = -1;
                        this.isDraggingRight = -1;

                    } else if (this.isDraggingLeft === -1) {
                        // Сохраняем левый объект, другого левого объекта не было
                        console.log('scenario !(this.isDraggingLeft >= 0)')
                        this.isDraggingLeft = i
                        this.gameObjectsLeft[`leftObject${i}`].changeObjectPicture(this.nodeImageGlow, this.nodeImageGlow, this.quest, this.name)
                    } else if ((this.isDraggingLeft >= 0)) {
                        // Сохраняем левый объект, другой левый объект был
                        console.log('scenario (this.isDraggingLeft >= 0)', this.isDraggingLeft)
                        this.gameObjectsLeft[`leftObject${this.isDraggingLeft}`].changeObjectPicture(this.nodeImage, this.nodeImageGlow, this.quest, this.name)
                        this.isDraggingLeft = i
                        this.gameObjectsLeft[`leftObject${i}`].changeObjectPicture(this.nodeImageGlow, this.nodeImageGlow, this.quest, this.name)
                    }
                }
            }

            for (let i=0; i<this.playableAreasRight.length; i++) {
                if (positionX >= this.playableAreasRight[i][0][0] && positionX <= this.playableAreasRight[i][1][0] &&
                    positionY >= this.playableAreasRight[i][0][1] && positionY <= this.playableAreasRight[i][1][1]) {

                    console.log(`I'm in the playable area right`, i)

                    if (this.isDraggingLeft >= 0) {
                        console.log('scenario this.isDraggingLeft')
                        this.gameObjectsRight[`rightObject${i}`].changeObjectPicture(this.nodeImageGlow, this.nodeImageGlow, this.quest, this.name)
                        this.isDraggingRight = i

                        // Соединяем
                        let qmap = document.querySelector(".QuestMap").getBoundingClientRect();
                        console.log(qmap)
                        
                        let transformFactor = utils.getTransformFactor(this.currentQuest);

                        let newLineObject = document.createElement("div");

                        let x1 = 0
                        let y1 = 0                        
                        let x2 = (this.rightLineCoordinates[i][0] - this.leftLineCoordinates[this.isDraggingLeft][0]) * transformFactor
                        let y2 = (this.rightLineCoordinates[i][1] - this.leftLineCoordinates[this.isDraggingLeft][1]) * transformFactor

                        let height = 0;
                        let width = 0;

                        let left = 0;
                        let top = 0;

                        if (y2 >= 0) {
                            newLineObject.style.left = `${this.leftLineCoordinates[this.isDraggingLeft][0] * transformFactor}px`;
                            newLineObject.style.top = `${this.leftLineCoordinates[this.isDraggingLeft][1] * transformFactor}px`;

                            left = this.leftLineCoordinates[this.isDraggingLeft][0] * transformFactor;
                            top = this.leftLineCoordinates[this.isDraggingLeft][1] * transformFactor;

                            height = y2-y1+100;
                            width = x2-x1;
                        } else if (y2 < 0) {
                            newLineObject.style.left = `${this.leftLineCoordinates[this.isDraggingLeft][0] * transformFactor}px`;
                            newLineObject.style.top = `${this.rightLineCoordinates[this.isDraggingRight][1] * transformFactor}px`;

                            left = this.leftLineCoordinates[this.isDraggingLeft][0] * transformFactor;
                            top = this.rightLineCoordinates[this.isDraggingRight][1] * transformFactor;

                            height = -y2
                            width = x2-x1

                            let y1_ = -y2
                            let y2_ = 0

                            y1 = y1_
                            y2 = y2_
                        }

                        let stroke = 5;

                        if (((y1 - y2 < 1) && (y1 >= y2)) || ((y2 - y1 < 1) && (y1 <= y2))) {
                            console.log(y1, y2)
                            stroke = 10;
                        }

                        
                        newLineObject.innerHTML = (`
                            <svg height="${height}" width="${width}" xmlns="http://www.w3.org/2000/svg">
                                <line x1="${x1}" y1="${y1}" x2="${x2}" y2="${y2}" style="stroke:black;stroke-width:${stroke}" />
                            </svg>
                        `);
                        newLineObject.style['position'] = 'absolute'
                        newLineObject.style['transform-origin'] = 'top left'
                        newLineObject.classList.add(`line-${this.isDraggingLeft}`);

                        this.lineObjectsInfo[`line-${this.isDraggingLeft}`] = [height, width, x1, y1, x2, y2, left, top, transformFactor]
                        
                        console.log('NEW LINE OBJECT', newLineObject)
                        console.log('Dragging objects', this.isDraggingLeft, this.isDraggingRight)

                        // Удаляем все мэтчи, которые связывали this.isDraggingLeft или this.isDraggingRight
                        Object.keys(this.currentMatching).forEach(key => {
                             if ((Number(key) === this.isDraggingLeft) ) {
                                // Меняем картинку тому ноду, который был соединен с левым
                                console.log('Мы должны поменять картинку правому ноду')

                                if (this.currentMatching[key] !== this.isDraggingRight) {
                                    this.gameObjectsRight[`rightObject${this.currentMatching[key]}`].changeObjectPicture(this.nodeImage, this.nodeImageGlow, this.quest, this.name)
                                }
                                document.querySelector(`.line-${key}`).remove()
                                // console.log(document.querySelectorAll(`.line-${key}`), `line-${key}` )
                                delete this.currentMatching[key]
                            }

                            // if ((this.currentMatching[key] === this.isDraggingRight) && (Number(key) !== this.isDraggingLeft)) {
                            if ((this.currentMatching[key] === this.isDraggingRight)) {
                                console.log('Мы должны поменять картинку левому ноду')
                                
                                if (Number(key) !== this.isDraggingLeft) {
                                    this.gameObjectsLeft[`leftObject${key}`].changeObjectPicture(this.nodeImage, this.nodeImageGlow, this.quest, this.name)
                                }
                                document.querySelector(`.line-${key}`).remove()
                                // console.log(document.querySelectorAll(`.line-${key}`), `line-${key}` )
                                delete this.currentMatching[key]
                            }
                        })

                        container = document.querySelector(".QuestMap")
                        container.insertBefore(newLineObject, container.firstChild);

                        // Создаем новый матч
                        this.currentMatching[this.isDraggingLeft] = this.isDraggingRight

                        console.log(this.currentMatching)
                        this.isDraggingLeft = -1;
                        this.isDraggingRight = -1;

                    } else if (this.isDraggingRight === -1) {
                        // Сохраняем левый объект, другого левого объекта не было
                        console.log('scenario !(this.isDraggingRight >= 0)', this.isDraggingRight)
                        this.isDraggingRight = i
                        this.gameObjectsRight[`rightObject${i}`].changeObjectPicture(this.nodeImageGlow, this.nodeImageGlow, this.quest, this.name)
                    } else if ((this.isDraggingRight >= 0)) {
                        // Сохраняем левый объект, другой левый объект был
                        console.log('scenario (this.isDraggingRight >= 0)', this.isDraggingRight)
                        this.gameObjectsRight[`rightObject${this.isDraggingRight}`].changeObjectPicture(this.nodeImage, this.nodeImageGlow, this.quest, this.name)
                        this.isDraggingRight = i
                        this.gameObjectsRight[`rightObject${i}`].changeObjectPicture(this.nodeImageGlow, this.nodeImageGlow, this.quest, this.name)
                    }
                
                }

            }

            // Проверка на то что паззл решен
            let puzzleSolved = true;

            // Проверяем сначала, что все фигурки расставлены
            if (Object.keys(this.correctMatching).length === Object.keys(this.currentMatching).length) {
                // А потом что совпадают значения ключей
                Object.keys(this.correctMatching).forEach(key => {
                    if (this.correctMatching[key] !== this.currentMatching[key]) {
                        puzzleSolved = false;
                    }
                })
            } else {
                puzzleSolved = false;
            }
    
            if (puzzleSolved) {
                // console.log('HORRAY!');
                this.isSolved = true;
                this.quest.executeEvents(this.onSolveEvents);
            }
    
        }

        window.addEventListener('click', this.clickHandler);

        window.addEventListener('resize', () =>{

            // console.log('RESIZE WORKING')
            let transformFactor = utils.getTransformFactor(this.currentQuest);

            Object.keys(this.currentMatching).forEach(key => {

                let lineObjectName = `line-${key}`
               
                let objectDiv = document.querySelector(`.${lineObjectName}`)

                let lineObjectInfo = this.lineObjectsInfo[lineObjectName];
                let leftCoord = lineObjectInfo[6];
                let topCoord = lineObjectInfo[7];
                let oldTransformFactor = lineObjectInfo[8];

                objectDiv.style.left = `${leftCoord / oldTransformFactor * transformFactor}px`
                objectDiv.style.top = `${topCoord / oldTransformFactor * transformFactor}px`
                objectDiv.style.transform = `scale(${transformFactor / oldTransformFactor})`
            })
            });
    }

    // init() и createElement() не меняются, только тип gameObject'а
}