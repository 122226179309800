import CodePuzzle from "./CodePuzzle";
import GameObject from "./GameObject";
import { utils } from "./utils";

export default class Recorder extends CodePuzzle {
    constructor(config) {
        super(config);

        // Массив решенных кодов
        this.solvedCodes = [];

        // Панель ввода кодов
        this.inputButtons = config.inputButtons;
        this.maxCodeLength = config.maxCodeLength || 4;
        this.solvedCodes = config.solvedCodes || [];
        this.correctCodes = config.correctCodes;
        this.currentCode = '';
        this.codeSounds = config.codeSounds || [];

        this.displayCode = config.displayCode || false;
        this.x = config.x || 3024;
        this.y = config.y || 1140;
        this.width = config.width || 477;
        this.height = config.height || 176;
        this.fontSize = config.height || 176;
        this.backgroundColor = config.backgroundColor || '#89A0A9';

        this.numberButtonSrc = config.numberButtonSrc;

        this.numberButtonSrcGlow = config.numberButtonSrcGlow;

        this.numberButtonCoords = config.numberButtonCoords ;

        // Что происходит после ввода каждого из кодов
        this.onSolveEvents = config.onSolveEvents;

        this.onSolvedAll = config.onSolvedAll;

    }

    createElement() {
        this.element = document.createElement("div");
        this.element.classList.add("QuestMap");
        this.element.style.backgroundImage = `url('${this.src}')`;

        let transformFactor = utils.getTransformFactor(this.currentQuest);

        // Создаем отображение введенного кода
        let newTextElement = document.createElement("p");
        newTextElement.classList.add(`CodePuzzleText`);
        newTextElement.textContent = this.currentCode;
        newTextElement.style.position = `absolute`;
        newTextElement.style.left = `${this.x * transformFactor}px`;
        newTextElement.style.top = `${this.y * transformFactor}px`;
        newTextElement.style.height = `${this.height * transformFactor}px`;
        newTextElement.style.width = `${this.width * transformFactor}px`;

        newTextElement.style['background-color'] = this.backgroundColor;
        newTextElement.style['font-size'] = `${this.fontSize * transformFactor}px`;

        newTextElement.style['text-align'] = 'center';
        newTextElement.style['vertical-align'] = 'middle';
        newTextElement.style['line-height'] = `${this.height * transformFactor}px`;

        newTextElement.style['margin-top'] = `0px`;
        newTextElement.style['margin-bottom'] = `0px`;
        if (this.displayCode) {
            this.element.appendChild(newTextElement);
        }

        window.addEventListener('resize', () =>{
            console.log('resize!!!')
            let transformFactor = utils.getTransformFactor(this.currentQuest);

            newTextElement.style.left = `${this.x * transformFactor}px`;
            newTextElement.style.top = `${this.y * transformFactor}px`;
            newTextElement.style.height = `${this.height * transformFactor}px`;
            newTextElement.style.width = `${this.width * transformFactor}px`;

            newTextElement.style['font-size'] = `${this.fontSize * transformFactor}px`;
            newTextElement.style['line-height'] = `${this.height * transformFactor}px`;

       });
        
    };

    addButtonElements() {

        if (this.codeSounds.length > 0) {
            for (let i=0; i < this.numberButtonCoords.length; i++) {
                let numberButton = new GameObject({
                    onClickEvents: [{type:'enterCode',
                                    number: i+1, 
                                    map: this},
                                    {type: 'playSound', 
                                    playTimeOut: 2000,
                                    soundEffects: [this.codeSounds[i] ]
                                    }
                                    ],
                    map: this,
                    src: this.numberButtonSrc[i],
                    src_glow: this.numberButtonSrcGlow[i],
                    x: this.numberButtonCoords[i][0],
                    y: this.numberButtonCoords[i][1],
                })
                numberButton.init({map: this,
                    container:this.element})
            }
        } else {
            for (let i=0; i < this.numberButtonCoords.length; i++) {
                let numberButton = new GameObject({
                    onClickEvents: [{type:'enterCode',
                                    number: i+1, 
                                    map: this}],
                    map: this,
                    src: this.numberButtonSrc[i],
                    src_glow: this.numberButtonSrcGlow[i],
                    x: this.numberButtonCoords[i][0],
                    y: this.numberButtonCoords[i][1],
                })
                numberButton.init({map: this,
                    container:this.element})
            }
        }
        
    };



}