import GameObject from "./GameObject";
import { utils } from "./utils";

export default class InventoryObject extends GameObject {
    constructor(config) {
        super(config);
        this.inventory = config.inventory;
        this.order = config.order;

        this.currentQuest = window.location.pathname.split('/')[2]
    };

    createElement() {

        this.src = `${process.env.REACT_APP_API_URL}${this.currentQuest}/images/inventory/${this.name}-inventory.${utils.get_gof(this.currentQuest)}`;
        this.src_glow = `${process.env.REACT_APP_API_URL}${this.currentQuest}/images/inventory/${this.name}-inventory-glow.${utils.get_gof(this.currentQuest)}`;

        let transformFactor = utils.getTransformFactor(this.currentQuest);
        let scaleFactor = utils.getScaleFactor(this.currentQuest);
        
        this.gameObjectElement = document.createElement("img");
        this.gameObjectElement.classList.add("GameObject");
        this.gameObjectElement.setAttribute("src", this.src );
        this.gameObjectElement.style.left = `${this.x * transformFactor}px`;
        this.gameObjectElement.style.top = `${this.y * transformFactor}px`; 
        this.gameObjectElement.style.transform = `scale(${scaleFactor})`
    }

    init(container) {
        this.createElement();
        this.gameObjectElement.addEventListener('mouseover', () => {
            this.gameObjectElement.setAttribute("src", this.src_glow );
        });

        this.gameObjectElement.addEventListener('mouseout', () => {
            this.gameObjectElement.setAttribute("src", this.src );
        });

        this.gameObjectElement.addEventListener('click', () => {
            // this.map.executeEvents(this.onClickEvents);
        });
        // console.log(container, this.gameObjectElement);
        container.appendChild(this.gameObjectElement);
    }
}