import QuestMap from "./QuestMap";
import GameObject from "./GameObject";
import { utils } from "./utils";
import NavigationButton from "./NavigationButton";

export default class InputPuzzle extends QuestMap {
    constructor(config) {
        super(config);

        let size = utils.getSize(this.currentQuest)

        this.inputFieldX = (config.inputFieldX || 1148) * (size/3840);
        this.inputFieldY = (config.inputFieldY || 1972) * (size/3840);
        this.inputFieldWidth = (config.inputFieldWidth || 1112) * (size/3840);
        this.inputFieldHeight = (config.inputFieldHeight || 147) * (size/3840);
        this.inputFieldFontSize = (config.inputFieldFontSize || 80) * (size/3840);
        this.inputFieldText = config.inputFieldText || 'enter code';

        this.correctCode = config.correctCode || 'ACEIJZYVS';

        this.borderWidth = (config.borderWidth || 10) * (size/3840);
        this.borderColor = config.borderColor || '#37261E';

        this.backgroundColor = config.backgroundColor || '#C5A383';

        this.customArrowsSrc = config.customArrowsSrc || {};

        this.onSolveEvents = config.onSolveEvents || []
    }

    addNavigationElements() {
        Object.keys(this.quest.navigationStructure[this.name]).forEach(key => {

            if (Object.keys(this.customArrowsSrc).includes(key)) {
                let navigationButton = new NavigationButton({
                    direction: key,
                    src: this.customArrowsSrc[key][0],
                    src_glow: this.customArrowsSrc[key][1],
                    x: this.customArrowsSrc[key][2],
                    y: this.customArrowsSrc[key][3],
                    onClickEvents: [{type:'changeMap', map: this.quest.navigationStructure[this.name][key]}],
                    map: this.name,
                })
                navigationButton.init({map: this,
                    container:this.element})
            } else {
                let navigationButton = new NavigationButton({
                    direction: key,
                    onClickEvents: [{type:'changeMap', map: this.quest.navigationStructure[this.name][key]}],
                    map: this.name,
                })
                navigationButton.init({map: this,
                    container:this.element})
            }
        })

        let navigationButton = new GameObject({
            name: 'hintsMenuButton',
            x: 13,
            y: 13,
            src: `${process.env.REACT_APP_API_URL}${this.currentQuest}/images/backgrounds/hintsMenuButton.${utils.get_gof(this.currentQuest)}`,
            src_glow: `${process.env.REACT_APP_API_URL}${this.currentQuest}/images/backgrounds/hintsMenuButton-glow.${utils.get_gof(this.currentQuest)}`,
            onClickEvents: [{type:'openHintsMenu'}],
            map: this.name,
        })

        navigationButton.init({map: this,
            container:this.element})

        if (this.quest.mapEnabled) {
            let mapMenuButton = new GameObject({
                name: 'mapMenuButton',
                x: 3494,
                y: 0,
                src: `${process.env.REACT_APP_API_URL}${this.currentQuest}/images/backgrounds/mapMenuButton.${utils.get_gof(this.currentQuest)}`,
                src_glow: `${process.env.REACT_APP_API_URL}${this.currentQuest}/images/backgrounds/mapMenuButton-glow.${utils.get_gof(this.currentQuest)}`,
                onClickEvents: [{type:'openMapMenu'}],
                map: this.name,
            })
    
            mapMenuButton.init({map: this,
                container:this.element})
        }

        let transformFactor = utils.getTransformFactor(this.currentQuest);

        let newInputElement = document.createElement("input");
        newInputElement.type = 'text'
        newInputElement.placeholder = this.inputFieldText;
        newInputElement.id = 'txtInputData'
        
        newInputElement.style.position = `absolute`;

        newInputElement.style.left = `${this.inputFieldX * transformFactor }px`;
        newInputElement.style.top = `${this.inputFieldY * transformFactor}px`;
        newInputElement.style.height = `${this.inputFieldHeight * transformFactor}px`;
        newInputElement.style.width = `${this.inputFieldWidth * transformFactor}px`;

        // newInputElement.style['background-color'] = this.backgroundColor;
        newInputElement.style['font-size'] = `${this.inputFieldFontSize * transformFactor}px`;

        newInputElement.style['border'] = `${this.borderWidth * transformFactor}px solid ${this.borderColor}`;
        newInputElement.style['background-color'] = `${this.backgroundColor}`;

        newInputElement.style['text-align'] = 'center';
        newInputElement.style['vertical-align'] = 'middle';
        newInputElement.style['line-height'] = `${this.inputFieldHeight * transformFactor}px`;

        // newInputElement.placeholder.color = `${this.borderColor}`;

        newInputElement.style['margin-top'] = `0px`;
        newInputElement.style['margin-bottom'] = `0px`;

        this.element.appendChild(newInputElement);

        window.addEventListener('resize', () =>{
            let transformFactor = utils.getTransformFactor(this.currentQuest);
            newInputElement.style.transform = `scale(${transformFactor})`
            newInputElement.style.left = `${this.inputFieldX * transformFactor}px`;
            newInputElement.style.top = `${this.inputFieldY * transformFactor}px`;
        });
    };


    // init() и createElement() не меняются, только тип gameObject'а
}