import QuestMap from "./QuestMap";
import NavigationButton from "./NavigationButton";
import { utils } from "./utils";

export default class Narration extends QuestMap {
    constructor(config) {
        super(config);

        this.displayText = config.displayText || [];
        // this.followingMap = config.followingMap || 'grandpaCabinet1';
        this.stageCounter = 0;
        this.onSolveEvents = config.onSolveEvents || [];
        this.customStartingObject = config.customStartingObject ;
        this.customStartingObjectGlow = config.customStartingObjectGlow ;
        this.customX = config.customX;
        this.customY = config.customY;
    }

    addNavigationElements() {

        console.log('inserting navigation elements')

        // Если нам есть какой текст отображать
        if (this.displayText.length > 0) {

            if (this.customStartingObject && this.stageCounter === 0) {
                // Создаем кнопку "направо" стандартного формата
                let navigationButton = new NavigationButton({
                    direction: 'right',
                    src: this.customStartingObject,
                    src_glow: this.customStartingObjectGlow,
                    // customClass: 'nonRemovable',
                    x: this.customX,
                    y: this.customY,
                    onClickEvents: [{
                                        type:'increaseNarrative', 
                                        map: this.name, 
                                        stageCounter: this.stageCounter, 
                                        displayText: this.displayText
                                    }],
                    map: this.name,
                })
        
                console.log('initializing navigation elements')
                
                // Инициализируем ее
                navigationButton.init({map: this,
                    container:this.element})
            }

            else {
                // Создаем кнопку "направо" стандартного формата
                let navigationButton = new NavigationButton({
                    direction: 'right',
                    // increaseNarrative создаст диалоговое окно, если stageCounter = 0
                    // Меняет текст, если stageCounter < len(displayText)
                    // выполняет onSolveEvents, если 
                    onClickEvents: [{
                                        type:'increaseNarrative', 
                                        map: this.name, 
                                        stageCounter: this.stageCounter, 
                                        displayText: this.displayText
                                    }],
                    map: this.name,
                })
        
                console.log('initializing navigation elements')
                
                // Инициализируем ее
                navigationButton.init({map: this,
                    container:this.element})
            }
            
        }
    };

    reinitNavigationElements(stageCounter) {

        let navigationElements = document.querySelectorAll(".NavigationButton")

        navigationElements.forEach(ne => {
            ne.remove()
        })
        
        this.addNavigationElements()
    };

    init(container) {

        console.log(this)
        this.createElement();

        container.insertBefore(this.element, container.firstChild);
        
        this.mountObjects();
        this.addNavigationElements();
    };
}