import { utils } from "./utils";
import {Howl} from 'howler';
// import QuestEvent from "./QuestEvent";

export default class GameObject {
    constructor(config) {
        // Заполняется при создании элемента или сразу, если кастомный код
        this.map = config.map || null;
        this.src = config.src || null;
        this.src_glow = config.src_glow || null;
        this.currentQuest = window.location.pathname.split('/')[2]
        
        this.name = config.name;
        this.x = config.x || 0;
        this.y = config.y || 0;
        this.z = config.z;
        this.onClickEvents = config.onClickEvents || [];

        this.nonClickable = config.nonClickable || false;

        this.isCollectable = config.isCollectable || false;
        this.isRemovable = !(!config.isRemovable || false);
        this.collectableType = config.collectableType || null;
        this.weight = config.weight;
        
    };

    async createElement(map) {

        this.map = map;

        let newLevelPictureName = this.map.name

        if (this.map.src) {
            // console.log('got here!!')
            let newLevelPictureName = this.map.src.split('?')[0].split('/').slice(-1)[0]
            newLevelPictureName = newLevelPictureName.replace(`.${utils.get_gof(this.currentQuest)}`, '')

            // console.log(this.map, this.map.quest)

            this.map.quest.mappings.forEach(mapping => {
                Object.keys(mapping).forEach(mappingKey => {
                    newLevelPictureName = newLevelPictureName.replace(mappingKey, mapping[mappingKey])
                })
            })
            newLevelPictureName = newLevelPictureName.split('-')[0]
        } else {
            newLevelPictureName = this.map.name
        }
        
        // console.log(this.map.quest.mappings, newLevelPictureName)

        // console.log('PROCESS_ENV', process.env)

        if (!this.src) {
            this.src = `${process.env.REACT_APP_API_URL}${this.currentQuest}/images/objects/${newLevelPictureName}-${this.name}.${utils.get_gof(this.currentQuest)}`;
        }

        if (!this.src_glow) {
            this.src_glow = `${process.env.REACT_APP_API_URL}${this.currentQuest}/images/objects/${newLevelPictureName}-${this.name}-glow.${utils.get_gof(this.currentQuest)}`;
        }

        // console.log(this.src, this.src_glow)

        this.gameObjectElement = document.createElement("img");
        this.gameObjectElement.classList.add("GameObject");
        this.gameObjectElement.classList.add(this.name);

        this.gameObjectElement.setAttribute("src", this.src );

        let transformFactor = utils.getTransformFactor(this.currentQuest);
        let scaleFactor = utils.getScaleFactor(this.currentQuest);

        this.gameObjectElement.style.transform = `scale(${scaleFactor})`

        this.gameObjectElement.style.left = `${this.x * transformFactor}px`;
        this.gameObjectElement.style.top = `${this.y * transformFactor}px`;

        if (this.z) {
            this.gameObjectElement.style['z-index'] = this.z;
        }

        // console.log('gameObjectElement', this.gameObjectElement)
        
        if (this.map.class === 'QuestMap' && this.isRemovable) {
            this.map.gameObjects[this.name]['gameObjectElement'] = this.gameObjectElement;
        }
    }

    changeObjectPicture(newSrc, newSrcGlow, quest, gameObjMapName) {
        // Поменяли внутри объекта
        this.src = newSrc;
        this.src_glow = newSrcGlow;

        // Поменяли на элементе
        if (this.gameObjectElement) {
            this.gameObjectElement.src = newSrc;
            // console.log('Changed source on the element', this.name)
        } else {
            // console.log('Could not change source on the element', this.name)
        }
        
        // Поменяли в общей структуре
        // Для карт типа Marching будет всегда падать в catch
        try {
            quest.mapStructure[gameObjMapName].gameObjects[this.name].src = newSrc;
            quest.mapStructure[gameObjMapName].gameObjects[this.name].src_glow = newSrcGlow;
        } catch {

        }
    }

    init({map, container}) {
        this.createElement(map);

        if (!this.nonClickable) {
            this.gameObjectElement.addEventListener('mouseover', () =>{
                this.gameObjectElement.setAttribute("src", this.src_glow );
            });

            this.gameObjectElement.addEventListener('mouseout', () =>{
                this.gameObjectElement.setAttribute("src", this.src );
            });

            this.gameObjectElement.addEventListener('click', () => {

                let howl = new Howl({
                    src: [
                       `${process.env.REACT_APP_API_URL}${this.currentQuest}/soundEffects/clickSound.mp3`
                    ],
                    volume: 0.05
                 })

                howl.play();

                if (this.isCollectable) {

                    this.map.quest.executeEvents([
                    {type:'addInventory', gameObjects: [new GameObject({
                        name: this.name,
                        weight: this.weight,
                        isCollectable: this.isCollectable, 
                        collectableType: this.collectableType})]
                    },
                    {type:'removeGameObjects', gameObjects: [this.name], map: this.map.name}])
                    // console.log('ONCLICKEVENTS', this.onClickEvents)
                    this.map.quest.executeEvents(this.onClickEvents);
                    
                }
                else {
                    // console.log('ONCLICKEVENTS', this.onClickEvents)
                    this.map.quest.executeEvents(this.onClickEvents);
                    }
            });

        }

        window.addEventListener('resize', () =>{
            let transformFactor = utils.getTransformFactor(this.currentQuest);
            let scaleFactor = utils.getScaleFactor(this.currentQuest);

            this.gameObjectElement.style.transform = `scale(${scaleFactor})`
            this.gameObjectElement.style.left = `${this.x * transformFactor}px`;
            this.gameObjectElement.style.top = `${this.y * transformFactor}px`;
        });
  
        container.appendChild(this.gameObjectElement);

    }

}